import React from "react";

function PointsTable() {
  return (
    <div className="pointstable">
      <h1>Points</h1>
    </div>
  );
}

export default PointsTable;
