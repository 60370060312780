import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiFillHome size={24} />,
    cName: "nav-text",
  },
  {
    title: "Pick",
    path: "/pick",
    icon: <AiIcons.AiOutlineSelect size={24} />,
    cName: "nav-text",
  },
  {
    title: "Points Table",
    path: "/points-table",
    icon: <FaIcons.FaTrophy size={24} />,
    cName: "nav-text",
  },
  {
    title: "Profile",
    path: "/profile",
    icon: <FaIcons.FaUser size={24} />,
    cName: "nav-text",
  },
];
