import React, { useState, useEffect } from "react";
import fire from "./fire";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import Login from "./Login";
import Hero from "./Hero";
import "./App.css";

function setInputError(inputElement, message) {
  inputElement.classList.add("form__input--error");
  inputElement.parentElement.querySelector(
    ".form__input-error-message"
  ).textContent = message;
}

function clearInputError(inputElement) {
  inputElement.classList.remove("form__input--error");
  inputElement.parentElement.querySelector(
    ".form__input-error-message"
  ).textContent = "";
}

document.addEventListener("DOMContentLoaded", () => {
  const loginForm = document.querySelector("#login");
  const createAccountForm = document.querySelector("#createAccount");

  document
    .querySelector("#linkCreateAccount")
    .addEventListener("click", (e) => {
      e.preventDefault();
      loginForm.classList.add("form--hidden");
      createAccountForm.classList.remove("form--hidden");
    });

  document.querySelector("#linkLogin").addEventListener("click", (e) => {
    e.preventDefault();
    loginForm.classList.remove("form--hidden");
    createAccountForm.classList.add("form--hidden");
  });

  document.querySelectorAll(".form__input").forEach((inputElement) => {
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    inputElement.addEventListener("blur", (e) => {
      if (
        (e.target.id === "createAccountEmail" ||
          e.target.id === "loginEmail") &&
        e.target.value.length > 0 &&
        !e.target.value.match(pattern)
      ) {
        setInputError(inputElement, "Invalid email address");
      }
      if (
        e.target.id === "createAccountConfirmPassword" &&
        e.target.value.length > 0
      ) {
        var password1 = document.getElementById("createAccountPassword");
        var password2 = document.getElementById("createAccountConfirmPassword");
        if (password1.value !== password2.value) {
          setInputError(inputElement, "Password mismatch");
        }
      }
    });
    inputElement.addEventListener("input", (e) => {
      if (e.target.id === "loginEmail" || e.target.id === "createAccountEmail")
        clearInputError(inputElement);
    });
  });
});

function App() {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const clearInputs = () => {
    setEmail("");
    setPassword("");
  };

  const clearErrors = () => {
    setPasswordError("");
  };

  const handleLogin = () => {
    clearErrors();
    signInWithEmailAndPassword(getAuth(fire), email, password).catch((err) => {
      switch (err.code) {
        case "auth/invalid-email":
          setPasswordError("Invalid Email Address");
          break;
        case "auth/user-not-found":
          setPasswordError("User Not Found");
          break;
        case "auth/user-disabled":
          setPasswordError("User Disabled");
          break;
        case "auth/wrong-password":
          setPasswordError("Incorrect Email/Password");
          break;
        default:
          setPasswordError("Unexpected Error. Try Again.");
      }
    });
  };

  const handleSignUp = () => {
    clearErrors();
    createUserWithEmailAndPassword(getAuth(fire), email, password).catch(
      (err) => {
        switch (err.code) {
          case "auth/email-already-in-use":
            setPasswordError("Email address already exists.");
            break;
          case "auth/invalid-email":
            setPasswordError("Invalid Email Address");
            break;
          case "auth/weak-password":
            setPasswordError("Password must be atleast 6 characters long.");
            break;
          default:
            setPasswordError("Unexpected Error. Try Again.");
        }
      }
    );
  };

  const handleLogout = () => {
    signOut(getAuth(fire));
  };

  const authListener = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        clearInputs();
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  return (
    <div className="App">
      {user ? (
        <Hero handleLogout={handleLogout} />
      ) : (
        <Login
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
          handleSignUp={handleSignUp}
          passwordError={passwordError}
        />
      )}
    </div>
  );
}

export default App;
