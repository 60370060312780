import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import Home from "./pages/Home";
import Pick from "./pages/Pick";
import PointsTable from "./pages/PointsTable";
import Profile from "./pages/Profile";

const Hero = ({ handleLogout }) => {
  return (
    <Router>
      <Navbar handleLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pick" element={<Pick />} />
        <Route path="/points-table" element={<PointsTable />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Router>
  );
};

export default Hero;
