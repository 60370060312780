import React from "react";

function Profile() {
  return (
    <div className="profile">
      <h1>Profile</h1>
    </div>
  );
}

export default Profile;
