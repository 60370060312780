import { initializeApp } from "firebase/app";

var firebaseConfig = {
  apiKey: "AIzaSyAwwajuvMn232l3A9d9jp4K9Zv4i305STI",
  authDomain: "tod-ipl.firebaseapp.com",
  projectId: "tod-ipl",
  storageBucket: "tod-ipl.appspot.com",
  messagingSenderId: "898547515501",
  appId: "1:898547515501:web:4f2fbb175ce7183def7f64",
};

const fire = initializeApp(firebaseConfig);

export default fire;
