import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import fire from "../fire";

const setPickForUser = (team) => {
  if (team === "team_1") {
    //Add team 1 to results table
  } else {
    //Add team 2 to results table
  }
};

function Pick() {
  const [teamPick, setTeamPick] = useState("");
  const [matchNum, setMatchNum] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const currentDate = new Date();
  const date = `${currentDate.getDate()}` + `${currentDate.getMonth() + 1}`;
  const db = getFirestore(fire);

  useEffect(() => {
    const q = query(collection(db, "matches"), where("match_date", "==", date));

    async function getData() {
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty()) {
        querySnapshot.forEach((doc) => {
          setTeam1(doc.get("team_1"));
          setTeam2(doc.get("team_2"));
        });
      } else {
        console.log("No matches");
      }
    }
    getData();
  }, []);

  const submitTeam1 = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "picks"), {
        pick: "team1",
        ui: "Kgw22bY0YqWmpLmd1KFOy30cjer2",
      });
      console.log("Doc written: ", docRef.id);
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const submitTeam2 = (e) => {
    e.preventDefault();
    db.collection("picks").add({
      pick: "team2",
      uid: "Kgw22bY0YqWmpLmd1KFOy30cjer2",
    });
  };

  return (
    <>
      <div className="pick">
        <h1>Race {matchNum}</h1>
      </div>
      <div className="pick">
        <button className="team_button">{team1}</button>
        <button className="team_button">{team2}</button>
      </div>
    </>
  );
}

export default Pick;
